import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';
import Typewriter from 'typewriter-effect';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};

  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .building {
    color: #8892b0;
  }
  .typing {
    display: inline-flex;
    color: #df5d2b;
    animation: fadeIn 8s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .h2-name {
    background-color: #f3ec78;
    background-image: -webkit-linear-gradient(-70deg, rgb(100, 255, 218), rgb(255, 148, 0));
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading h2-name">Nabil Madih.</h2>;
  // const three = <h3 className="big-heading">I build websites</h3>;
  // const three = <h3 className="big-heading">I build{" "}
  // <Typical
  // loop={Infinity}
  // wrapper = "b"
  // steps={['developer',
  // 1000,
  // 'designer',
  // 1000,
  // 'Youtuber',
  // 1000,
  // 'Open Sourcer',
  // 1000,
  // 'beatboxer(wannabe)',1000]}
  // >
  // </Typical>
  // </h3>;

  // const three = <h3 className="big-heading">I build{" "}<span className = "typing">
  //   <Typical
  //     loop={Infinity}
  //     wrapper = "b"
  //     steps={['developer',
  //       2000,
  //       'designer',
  //       2000,
  //       'Youtuber',
  //       2000,
  //       'Open Sourcer',
  //       2000,
  //       'beatboxer(wannabe)',2000]}
  //   >
  //   </Typical>
  // </span>
  // </h3>;

  const three = (
    <h3 className="big-heading building">
      I build{' '}
      <span className="typing">
        <Typewriter
          options={{
            strings: ['Websites', 'Java applications', 'software products'],
            autoStart: true,
            loop: true,
          }}
        />
      </span>
    </h3>
  );
  const four = (
    <p>
      I'm a software engineer based in Washington state, specializing in building (and occasionally
      designing) websites, Java applications and everything in between.
    </p>
  );

  const five = (
    <a href={`mailto:${email}`} className="email-link">
      Get In Touch
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
